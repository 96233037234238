<template>
  <div class="">
    <div class="columns mb-5">
      <div class="column is-relative">
        <figure class="image">
          <img class="object-fit-contain" :src="item.src_left" :alt="item.alt_left">
        </figure>
        <span class="centering has-text-theme-red has-background-white-transparent px-3 to-bottom-left is-size-4">Before</span>
      </div>
      <div class="column is-relative">
        <figure class="image">
          <img class="object-fit-contain" :src="item.src_right" :alt="item.alt_right">
        </figure>
         <span class="centering has-text-white has-background-theme-red px-3 to-bottom-left is-size-4">After</span>
      </div>
    </div>
    <h4 class="title is-4 has-text-centered has-background-dark has-text-white py-2 mb-6">{{item.title}}</h4>
    <p class="title is-6 has-text-centered mb-6 p-3-mobile m-3-mobile" v-if="false">{{item.subtitle}}</p>
    <p class="p-3-mobile m-3-mobile" v-if="false">{{item.text}}</p>
  </div>
</template>

<script>
import config from './../config.json';

export default {
  name: 'parts-case-study',
  components: {
  },
  props: {
    showIndex: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return config.CASE_STUDY_CONTENTS;
    },
    item() {
      return this.CONTENTS[this.showIndex];
    }
  }
}
</script>

<style scoped>
.to-bottom-left {
  top: 90% !important;
  left: 88% !important;
}
</style>
