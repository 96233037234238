import { createApp } from 'vue';
import App from './App.vue';

import './assets/style.css';

const app = createApp(App);
app.mount('#app');

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

});

const scrollElements = document.querySelectorAll(".js-scroll");//ターゲット指定
 
const elementInView = (el, dividend = 1) => {
  const elementTop = el.getBoundingClientRect().top;
 
  return (
    elementTop <=
    (window.innerHeight || document.documentElement.clientHeight) / dividend
  );
};
 
const elementOutofView = (el) => {
  const elementTop = el.getBoundingClientRect().top;//ページの上部から要素の距離を取得
 
  return (
    elementTop > (window.innerHeight || document.documentElement.clientHeight)//ビューポートの高さを取得
  );
};
 
const displayScrollElement = (element) => {
  element.classList.add("scrolled");//スクロールで表示されたら要素にクラス名を割り当てる
};
 
const hideScrollElement = (element) => {
  element.classList.remove("scrolled");//スクロールで非表示になったら要素からクラス名を除去する
};
 
const handleScrollAnimation = () => {
  scrollElements.forEach((el) => {
    if (elementInView(el, 1.25)) {
      displayScrollElement(el);
    } else if (elementOutofView(el)) {
      hideScrollElement(el)
    }
  })
};

//const handleHeader = () => {
//  const fv = document.getElementById('fv');
//  if (fv) {
//    const elementBottom = fv.getBoundingClientRect().bottom;//FVの底
//    const top = document.scrollingElement.scrollTop;
//    const show = (top < elementBottom) ? 'flex' : 'none';
//    document.querySelector('.navbar.is-fixed-top').style.display = show;
//    console.log(top, elementBottom, show);
//  }
//};

 
window.addEventListener("scroll", () => { 
  handleScrollAnimation();
  //handleHeader();
});