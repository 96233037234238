<template>
  <div class="columns is-multiline">
    <div class="column is-4 is-relative my-5 py-5 is-clickable" v-for="(item, index) in CONTENTS" :key="index" @click="move(item.caption)">
      <figure class="image h-100">
        <img class="object-fit-cover h-100" :src="item.src" :alt="item.alt">
      </figure>
      <div class="centering has-background-theme-red-transparent p-2 is-flex is-justify-content-space-evenly" style="width:65%;top:95%;">
        <span class="has-text-weight-bold has-text-white is-size-4">{{item.caption}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { UTIL } from './../composables/util';
import config from './../config.json';

export default {
  name: 'parts-consultation',
  setup() {
    const { scrollTo } = UTIL();
    return {
      scrollTo,
    };
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return config.CONSULTATION_CONTENTS;
    }
  },
  methods: {
    move(target) {
      const inner_d = document.getElementById('FormTool_f050').contentWindow.document;
      Array.from(inner_d.querySelector('[name="p001"]').options).forEach((el, n, all) => {
          const value = el.value.trim();
          if (value === target) {
              all[n].selected = true;
          }
      });
      this.scrollTo('contact-desc');
    }
  }
}
</script>

<style scoped>
.h-100 {
  height: 100%;
}
</style>
