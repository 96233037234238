export const UTIL = () => {
  const scrollTo = (id) => {
    console.log(id);
    const top = document.getElementById(id).getBoundingClientRect().top;
    const yOffset = window.pageYOffset;
    const header = document.querySelector('.navbar').getBoundingClientRect().height;
    window.scroll({top: (top + yOffset - header), behavior: 'smooth'});
  };

  return {
    scrollTo,
  };
};