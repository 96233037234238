<template>
  <iframe frameborder="0" scrolling="no" src="../form_air/index.php" id="FormTool_f050" class="mt-6" width="100%" ref="iframe"></iframe> 
</template> 
  
<script>
export default {
  name: 'parts-contact-iframe',
  data() {
    return {
    }
  },
  mounted() {
    window.addEventListener('message', (e) => {
      const iframe = this.$refs.iframe;
      const eventName = e.data[0];
      const data = e.data[1];
      switch(eventName) {
          case 'setHeight':
              iframe.height = data;
              break;
          }
      }, false);
  }
}
</script>