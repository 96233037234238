<template>
  <template v-for="(qa, index) in QAs" :key="index">
    <PartsAccordionBox :is-show="isShows[index]" @toggle="isShows[index] = !isShows[index];" class="is-align-items-start is-justify-content-space-between">
      <template v-slot:title>
        <div class="is-flex">
          <span class="QA_Q"></span>
          <p class="is-size-5 has-text-weight-bold mx-4 mt-4">{{qa.q}}</p>
        </div>
      </template>
      <template v-slot:body>
        <div class="is-flex">
          <span class="QA_A"></span>
          <p class="mx-4 mt-4 w-80">{{qa.a}}</p>
        </div>
      </template>
    </PartsAccordionBox>
    <hr class="has-background-white" v-if="index < (QAs.length - 1)">
  </template>
</template>

<script>
import reduce from 'lodash/reduce';
import PartsAccordionBox from './PartsAccordionBox.vue';

import config from './../config.json';

export default {
  name: 'parts-faq',
  components: {
    PartsAccordionBox,
  },
  data() {
    return {
      isShows: {},
    };
  },
  computed: {
    QAs() {
      return config.QAS;
    }
  },
  created() {
    reduce(this.QAs, (results, value, key) => {
      results[key] = false;
      return results;
    }, this.isShows);
  }
}
</script>

<style scoped>
.QA_Q:before {
  content: url("../assets/img/icon_Q.png");
}

.QA_A:before {
  content: url("../assets/img/icon_A.png");
}
</style>
