<template>
  <div :class="$attrs.class">
    <div class="columns is-multiline is-justify-content-center">
      <template v-for="pageIndex in pages" :key="pageIndex">
        <div :class="`is-${columnWidth(pageIndex)}`" class="column mobile-w90" v-for="(item, index) in contentsByPagenation[pageIndex]" :key="index">
          <div class="card box-shadow-none">
            <div class="card-image">
              <figure class="image is-4by3">
                <img class="object-fit-cover" :src="item.src" :alt="item.alt">
              </figure>
            </div>
            <div class="card-content">
              <div class="content">
                <p class="title is-5">{{item.title}}</p>
                <p>{{item.text}}</p>
              </div>
              <time class="is-block has-text-right">{{item.createdAt}}</time>
            </div>
          </div>
        </div>
      </template>
    </div>
    <p class="is-flex is-justify-content-flex-end is-align-items-center">
      <span class="mr-3">AIR鉋コラム記事一覧</span>
      <button class="button has-background-black has-text-white is-rounded">
        <span class="icon" @click="toggle" v-if="isAll">
          <i class="fas fa-angle-right"></i>
        </span>
        <span class="icon" @click="toggle" v-else>
          <i class="fas fa-angle-up"></i>
        </span>
      </button>
    </p>
  </div>
</template>

<script>
import chunk from 'lodash/chunk';
import range from 'lodash/range';

import { UTIL } from './../composables/util';

export default {
  name: 'parts-columns',
  setup () {
    const { scrollTo } = UTIL();
    return {
      scrollTo,
    };
  },
  components: {
  },
  props: {
    chunkNum: {
      type: Number,
      default: 3,
    },
    contents: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      isAll: false,
    };
  },
  computed: {
    contentsByPagenation() {
      return chunk(this.contents, this.chunkNum);
    },
    pages() {
      return this.isAll ? range(this.contentsByPagenation.length): [0];
    }
  },
  methods: {
    columnWidth(pageIndex) {
      return (12 / this.contentsByPagenation[pageIndex].length);
    },
    toggle() {
      this.isAll = !this.isAll;
      this.scrollTo('column');
    }
  },
}
</script>

<style scoped>
</style>
