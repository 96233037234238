<template>
  
  <PartsFirstView>
    <template v-slot:header><PartsHeader/></template>
  </PartsFirstView>

  <article id="method" class="container js-scroll fade-in-bottom pb-0">
    <div class="jinja">
      <section class="inner">
        <PartsMethodTargets/>
      </section>
    </div>
    
    <div class="mokume">
      <section class="">
        <PartsAboutCircumstance/>
      </section>
    </div>

    <div class="mokume">
      <section class="inner">
        <PartsThemeTitle>
          寺・神社の木部を再生する<span class="has-text-theme-red">Air鉋工法</span>
        </PartsThemeTitle>
        <PartsAboutMethods/>
      </section>
    </div>
  </article>
  
  <article id="case-study" class="container js-scroll fade-in-bottom">
    <section class="inner lowered">
      <PartsThemeTitle class="pb-5 mb-6">
        木部再生<span class="has-text-theme-red">事例</span>
      </PartsThemeTitle>
      <PartsCaseStudy :show-index="0" />
    </section>
  </article>
  
  <article class="container js-scroll fade-in-bottom">
    <section class="inner lowered raised">
      <PartsCaseStudy :show-index="1" />
    </section>
  </article>
  
  <article class="container js-scroll fade-in-bottom">
    <section class="inner raised">
      <PartsCaseStudy :show-index="2" />
    </section>
  </article>
  
  <article id="targets" class="container js-scroll fade-in-bottom pb-0">
    <div class="mokume">
      <section class="inner">
        <PartsThemeTitle>
          <span class="has-text-theme-red">対応</span>範囲
        </PartsThemeTitle>
        <p class="has-text-centered py-6 is-size-4">以下のもので木部が対象。</p>
        <PartsConsultation/>
        <p class="has-text-centered py-6 is-size-4">金額は内容や場所により異なるため具体的な予算についてはお問い合わせください</p>
      </section>
    </div>
  </article>
  
  <article id="flow" class="container js-scroll fade-in-bottom">
    <section class="inner" style="min-width:90%;">
      <PartsThemeTitle class="pb-5 mb-6">
        ご利用の<span class="has-text-theme-red">流れ</span>
      </PartsThemeTitle>
      <PartsFlow/>
    </section>
  </article>
  
  <article id="column" class="container js-scroll fade-in-bottom pb-0">
    <div class="mokume">
      <section class="inner">
        <PartsThemeTitle class="pb-5 mb-6">
          新着<span class="has-text-theme-red">コラム</span>
        </PartsThemeTitle>
        <PartsColumns :contents="columns.CONTENTS" :chunk-num="3" class="" />
      </section>
    </div>
  </article>
  
  <article id="faq" class="container js-scroll fade-in-bottom">
    <section class="inner">
      <PartsThemeTitle>
        よくある<span class="has-text-theme-red">質問</span>
      </PartsThemeTitle>
      <PartsFaq/>
    </section>
  </article>

  <article id="contact" class="container js-scroll fade-in-bottom">
    <section class="inner">
      <PartsAccordionBox :is-show="isShowContact" @toggle="isShowContact = !isShowContact;" class="is-align-items-center is-justify-content-center">
        <template v-slot:title>
          <div class="has-text-centered pr-5 is-size-4 pb-6">寺・神社の木部再生技術「Air鉋」相談フォーム</div>
        </template>
          <template v-slot:body>
           <h3 id="contact-desc" class="is-over-under-line my-6">
            <p class="title is-5 mb-5">お急ぎの場合はお電話ください</p>
            <p class="title is-4">電話お問い合わせ先&emsp;TEL: 06-6696-7551</p>
            <p class="title is-5 mb-2">匿名によるお問い合わせはLINEトークでご連絡ください</p>
             <figure class="image m-auto line-qr p-3">
              <img class="object-fit-cover" :src="config.LINE_TALK.src" :alt="config.LINE_TALK.alt">
            </figure>
          </h3>
          <PartsContactIFrame/>
        </template>
      </PartsAccordionBox>
    </section>
  </article>

  <PartsFooter/>
</template>

<script>
import config from './config.json';
import columns from './columns.json'; 

import PartsThemeTitle from './components/PartsThemeTitle.vue';
import PartsHeader from './components/PartsHeader.vue';
import PartsFirstView from './components/PartsFirstView.vue';
import PartsMethodTargets from './components/PartsMethodTargets.vue';
import PartsAboutCircumstance from './components/PartsAboutCircumstance.vue';
import PartsAboutMethods from './components/PartsAboutMethods.vue';
import PartsCaseStudy from './components/PartsCaseStudy.vue';
import PartsConsultation from './components/PartsConsultation.vue';
import PartsFlow from './components/PartsFlow.vue';
import PartsColumns from './components/PartsColumns.vue';
import PartsFaq from './components/PartsFaq.vue';
import PartsContactIFrame from './components/PartsContactIFrame.vue';
import PartsFooter from './components/PartsFooter.vue';
import PartsAccordionBox from './components/PartsAccordionBox.vue';

export default {
  name: 'App',
  components: {
    PartsThemeTitle,
    PartsHeader,
    PartsFirstView,
    PartsMethodTargets,
    PartsAboutCircumstance,
    PartsAboutMethods,
    PartsCaseStudy,
    PartsConsultation,
    PartsFlow,
    PartsColumns,
    PartsFaq,
    PartsContactIFrame,
    PartsFooter,
    PartsAccordionBox,
  },
  data() {
    return {
      isShowContact: true,
      isShowAboutOsakazai: true,
    };
  },
  computed: {
    config() {
      return config;
    },
    columns() {
      return columns;
    }
  }
}
</script>

<style scoped>
.jinja {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /*background-image:linear-gradient(to right, rgba(255,255,255, 0.8) 0%, rgba(255,255,255, 0.8) 100%), url("./assets/img/bg-img_01.png")*/
  background-image:linear-gradient(to right, rgba(255,255,255, 0) 0%, rgba(255,255,255, 0) 100%), url("./assets/img/bg-img_01.png")
}
.mokume_40 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /*background-image:linear-gradient(to right, rgba(255,255,255, 0.8) 0%, rgba(255,255,255, 0.8) 40%, rgba(255,255,255, 1) 40%, rgba(255,255,255, 1) 100%), url("./assets/img/dummy_mokume.jpg")*/
  background-image:linear-gradient(to right, rgba(255,255,255, 0.8) 0%, rgba(255,255,255, 0.8) 40%, rgba(255,255,255, 0) 40%, rgba(255,255,255, 0) 100%), url("./assets/img/bg-img_02.png")
}
.mokume {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /*background-image:linear-gradient(to right, rgba(255,255,255, 0.8) 0%, rgba(255,255,255, 0.8) 100%), url("./assets/img/bg-img_01.png"),*/
  background-image:linear-gradient(to right, rgba(255,255,255, 0) 0%, rgba(255,255,255, 0) 100%), url("./assets/img/bg-img_02.png")
}
.line-qr {
  width: 150px;
  height: 150px;
}
</style>
