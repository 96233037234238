<template>
  <a id="scroll-to-top">
    <span class="icon is-block m-auto">
      <i class="fas fa-angle-up"></i>
    </span>
  </a>
  <footer class="footer py-2 pt-4 has-background-dark has-text-white">
    <div class="is-hidden-mobile is-flex is-justify-content-center">
      <div class="p-3">
        <a class="" href="/">
          <img class="object-fit-contain" src="../assets/img/logo_higashino_wt.png" width="320" height="53" style="max-height: none;">
        </a>
      </div>
      <div class="ml-6">
        <p class="is-size-6 p-4">株式会社東野材木店<br>
          〒558-0011 大阪市住吉区苅田8-7-20<br>
          TEL 06-6696-7551 ／ FAX 06-6696-7538<br>
        </p>
      </div>
    </div>
    <div class="columns is-hidden-tablet">
      <div class="column has-text-centered">
        <a class="" href="/">
          <img class="object-fit-contain" src="../assets/img/logo_higashino_wt.png" width="320" height="53" style="max-height: none;">
        </a>
      </div>
      <div class="column has-text-centered">
        <p class="is-size-7">株式会社東野材木店<br>
          〒558-0011 大阪市住吉区苅田8-7-20<br>
          TEL 06-6696-7551 ／ FAX 06-6696-7538<br>
        </p>
      </div>
    </div>
    <ul class="is-flex is-justify-content-space-between is-size-7 m-auto w-70 is-hidden">
      <template v-for="(menu, index) in menus" :key="menu.label">
        <li class="footer-menu">
          <a class="px-0 menu__item" :class="menu['footer-class']" :href="menu.href">
            {{menu.label}}
          </a>
        </li>
        <li v-if="index !== (menus.length - 1)">
          <span>|</span>
        </li>
      </template>
    </ul>
  </footer>
  <div class="has-text-white has-background-dark has-text-centered py-3">
    &copy; 2022 Higashino Timber.co.,Ltd.
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue';

import config from './../config.json';

export default {
  name: 'parts-footer',
  setup () {
    const uid = getCurrentInstance().uid;
    return {
      uid
    };
  },
  data() {
    return {
      menus: config.MENUS,
    }
  },
  mounted() {
    const scroll_to_top_btn = document.querySelector('#scroll-to-top');

    //クリックイベントを追加
    scroll_to_top_btn.addEventListener( 'click' , scroll_to_top );
    
    function scroll_to_top(){
      window.scroll({top: 0, behavior: 'smooth'});
    }
    
    //スクロール時のイベントを追加
    window.addEventListener( 'scroll' , scroll_event );
    
    function scroll_event(){
      
      if(window.pageYOffset > 400){
        scroll_to_top_btn.style.opacity = '1';
      } else	if (window.pageYOffset < 400) {
        scroll_to_top_btn.style.opacity = '0';
      }
    }
  },
}
</script>