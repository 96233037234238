<template>
  <nav class="navbar is-transparent is-absolute w-100" role="navigation" aria-label="main navigation">
    <div class="navbar-brand is-relative">
      <a class="navbar-item is-hidden-mobile ml-6 p-6" href="/">
        <img class="object-fit-contain" src="../assets/img/logo_higashino_wt.png" width="280" height="128" style="max-height: none;">
      </a>
      <a class="navbar-item centering is-hidden-tablet" href="/">
        <img class="object-fit-contain" src="../assets/img/logo_higashino_wt.png" width="280" height="128" style="max-height: none;">
      </a>
  
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" :data-target="`nabvar-${uid}`">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  
    <div :id="`nabvar-${uid}`" class="navbar-menu">
      <div class="navbar-end pt-3 pr-3 is-align-items-center">
        <a class="navbar-item px-0 menu__item" :class="menu['header-class']" :href="menu.href" v-for="menu in menus" :key="menu.label">
          {{menu.label}}
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { getCurrentInstance } from 'vue';

import config from './../config.json';

export default {
  name: 'parts-header',
  setup () {
    const uid = getCurrentInstance().uid;
  // ...
    return {
      uid
    };
  },
  data() {
    return {
      menus: config.MENUS,
      show: true,
    }
  },
}
</script>

<style scoped>
.navbar.is-transparent {
  background-color: transparent;
  background-image: none;
}
.navbar-burger {
  color: #fff;
}
.navbar-item {
  color: #fff;
  font-weight: bold;
}
.button.is-transparent {
  background-color: transparent;
  border-color: #fff;
}
@media screen and (max-width: 1023px) {
.navbar-item {
  color: unset;
}
.navbar-menu {
    //background-color: #000;
}
.has-text-left-mobile {
  text-align: left;
}
}
</style>