<template>
  <section class="hero is-fullheight is-relative">
    <slot name="header"/>
    <div class="hero-body p-0 is-hidden-mobile">
      <div class="fullscreen slideshow is-pc"
        :style="`background-image:linear-gradient(to right, ${rightRgba} 0%, ${rightRgba} 50%, ${leftRgba} 50%, ${leftRgba} 100%), url(${image.src});`"
        v-for="(image, index) in firstViewImages" :key="index">
      </div>
    </div>
    <div class="hero-body p-0 is-hidden-tablet">
      <div class="fullscreen slideshow is-sp"
        :style="`background-image:linear-gradient(to right, ${rightRgba} 0%, ${rightRgba} 50%, ${rightRgba} 50%, ${rightRgba} 100%), url(${image.src});`"
        v-for="(image, index) in firstViewImages" :key="index">
      </div>
    </div>
    <div class="has-text-white has-text-centered centering fv-caption has-text-centered">
      <p class="title is-4 has-text-white p-3 mincho is-hidden-tablet">寺・神社の木部再生技術</p>
      <p class="title is-3 has-text-white p-3 mincho is-hidden-mobile">寺・神社の木部再生技術</p>
      <figure class="image w-60 m-auto is-hidden-mobile">
        <img class="object-fit-contain" src="../assets/img/logo_air-kanna-pc.png" alt="AIR鉋 PC">
      </figure>
      <figure class="image w-60 m-auto is-hidden-tablet">
        <img class="object-fit-contain" src="../assets/img/logo_air-kanna-sp.png" alt="AIR鉋 SP">
      </figure>
      <p class="sub-title has-text-weight-bold p-3 is-hidden-tablet">寺・神社の建築物木部の修復をスピーディに行い、美観を再生<br>
        痛んだ部分を新しい木に取り替えたり、削ったりと膨大な時間と経費ををかけなくても<br>
        何十年、何百年と積み重ねた歴史を再現する「Air鉋」という新しい技術があります<br>
      </p>
      <p class="sub-title has-text-weight-bold p-3 is-size-4 is-hidden-mobile">寺・神社の建築物木部の修復をスピーディに行い、美観を再生<br>
        痛んだ部分を新しい木に取り替えたり、削ったりと膨大な時間と経費ををかけなくても<br>
        何十年、何百年と積み重ねた歴史を再現する「Air鉋」という新しい技術があります<br>
      </p>
      <div class="buttons is-justify-content-center mt-3">
        <button @click="scrollTo('method')" type="button py-3 px-4" class="button is-normal is-rounded has-background-theme-yellow has-text-white has-text-weight-semibold">&emsp;&emsp;工法を知る&emsp;&emsp; <span class="icon ml-2 has-text-white"><i class="fas fa-angle-right"></i></span></button>
        <button @click="scrollTo('case-study')" type="button py-3 px-4" class="button is-normal is-rounded has-background-whtie has-text-theme-red has-text-weight-semibold">木部再生事例を見る <span class="icon ml-2 has-text-theme-red"><i class="fas fa-angle-right"></i></span></button>
      </div>
    </div>
  </section>
</template>

<script>
import config from './../config.json';
import { UTIL } from './../composables/util';

export default {
  name: 'parts-first-view',
  setup() {
    const { scrollTo } = UTIL();
    return {
      scrollTo,
    };
  },
  components: {
  },
  data() {
    return {
      index: 0,
      firstViewImages: config.FIRST_VIEW_IMAGES,
      rightRgba: 'rgba(0, 0, 0, 0.5)',
      leftRgba: 'rgba(0, 0, 0, 0.5)'
    };
  },
  mounted() {
    const pcImages = document.querySelectorAll('.slideshow.is-pc');
    const spImages = document.querySelectorAll('.slideshow.is-sp');
    setInterval(() => {
      this.index = this.index < (this.firstViewImages.length - 1) ? this.index + 1 : 0;
      this.slideshow(pcImages);
      this.slideshow(spImages);
    }, 3000);
  },
  methods: {
    slideshow(images) {
      const current = images[this.index];
      const prev = images[this.index - 1] ? images[this.index - 1] : images[images.length - 1];
      current.classList.add('fadein');
      current.classList.remove('fadeout');
      prev.classList.remove('fadein');
      prev.classList.add('fadeout');
    }
  }
}
</script>

<style scoped>
.fadein {
  opacity: 1;
  transition: opacity 0.9s;
}

.fadeout {
  opacity: 0;
  transition: opacity 0.9s;
}

.slideshow {
  position: absolute;
}

.fullscreen {
  width: 100%;
  height: 100vh; /* 全画面表示 */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
</style>
