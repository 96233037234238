<template>
  <h3 class="title is-2 mt-3 has-text-centered mincho" :class="$attrs.class">
    <slot/>
  </h3>
</template>

<script>
export default {
  name: 'parts-theme-title',
  components: {
  },
  data() {
    return {
    };
  },
}
</script>

<style scoped>
</style>
