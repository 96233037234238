<template>
  <div class="columns is-gapless" v-for="(item, index) in CONTENTS" :key="index">
    <div class="column mt-6n">
      <figure class="image is-4by3">
        <img :src="item.src" :alt="item.alt">
      </figure>
    </div>
    <div class="column has-background-white">
      <div class="content p-6 m-6 p-4-mobile m-4-mobile">
        <p class="has-text-theme-red is-size-7 has-text-weight-bold mb-2">{{item.caption}}</p>
        <p class="title is-3">{{item.title}}</p>
        <p class="title is-4">{{item.subtitle}}</p>
        <p class="is-size-4" v-for="(text, index) in item.texts" :key="index">{{text}}&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<script>
//import config from './../config.json';

const CONTENTS = [
  {
    "src": "./static_img/img_01.png",
    "alt": "写真です",
    "caption": "世の中の流れ",
    "title": "タイトルです",
    "subtitle": "サブタイトルです",
    "texts": [
      "木曾路はすべて山の中である。あるところは岨づたいに行く崖の道であり、あるところは数十間の深さに臨む木曾川の岸であり、あるところは山の尾をめぐる谷の入り口である。一筋の街道はこの深い森林地帯を貫いていた",
      "",
      "木曾路はすべて山の中である。あるところは岨づたいに行く崖の道であり、あるところは数十間の深さに臨む木曾川の岸であり、あるところは山の尾をめぐる谷の入り口である。一筋の街道はこの深い森林地帯を貫いていた",
      "",
      "木曾路はすべて山の中である。あるところは岨づたいに行く崖の道であり、あるところは数十間の深さに臨む木曾川の岸であり、あるところは山の尾をめぐる谷の入り口である。一筋の街道はこの深い森林地帯を貫いていた",
    ]
  }
];

export default {
  name: 'parts-about-circumstance',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
</style>
