<template>
  <div class="title is-4 is-flex" :class="$attrs.class ? $attrs.class: 'is-align-items-center is-justify-content-space-evenly'">
    <slot name="title"/>
    <button class="button has-background-black has-text-white is-rounded is-hidden-mobile">
      <span class="icon" @click="$emit('toggle')" v-if="isShow">
        <i class="fas fa-2x fa-angle-up"></i>
      </span>
      <span class="icon" @click="$emit('toggle')" v-if="!isShow">
        <i class="fas fa-2x fa-angle-down"></i>
      </span>
    </button>
  </div>
  <div class="has-text-centered is-hidden-tablet">
    <button class="button has-background-black has-text-white is-rounded">
      <span class="icon" @click="$emit('toggle')" v-if="isShow">
        <i class="fas fa-2x fa-angle-up"></i>
      </span>
      <span class="icon" @click="$emit('toggle')" v-if="!isShow">
        <i class="fas fa-2x fa-angle-down"></i>
      </span>
    </button>
  </div>
  <transition name="fade">
    <div v-show="isShow">
      <slot name="body"/>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'parts-accordion-box',
  emits: ['toggle'],
  props: {
    isShow: {
      type: Boolean,
      require: true,
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
