<template>
  <p class="has-text-centered my-6 py-4 is-size-4">
    細かい木質チップを吹きかけて、表面の汚れた部分を除去します。<br>
    木質チップの種類や、圧力を変化させることで<br>
    「払う」「削る」「剥ぐ」「粗す」の四段を一台で行います。
  </p>

  <div class="columns is-gapless is-hidden-mobile" :class="((index % 2) === 1) ? 'is-flex-direction-row-reverse': ''" v-for="(item, index) in CONTENTS" :key="index">
    <div class="column">
      <figure class="image">
        <img class="object-fit-contain" :src="item.src" :alt="item.alt">
      </figure>
    </div>
    <div class="column has-background-white mt-10p z-1"
      :class="[((index % 2) === 1) ? 'mr-7n': 'ml-7n', ((CONTENTS.length - 1) === index ? '': 'mb-6')]">
      <div class="content p-6 is-relative has-background-kanji" :style="`background-image:url(${item.kanji})`">
        <p class="title is-3 has-text-theme-red">{{item.title}}</p>
        <p class="p-3 is-relative z-2 is-size-4" v-html="item.text"></p>
      </div>
    </div>
  </div>
  
  <div class="columns is-gapless is-hidden-tablet" v-for="(item, index) in CONTENTS" :key="index">
    <div class="column">
      <figure class="image">
        <img class="object-fit-contain" :src="item.src" :alt="item.alt">
      </figure>
    </div>
    
    <div class="column has-background-white w-90 mt-6n z-1 is-relative" style="" :style="((index % 2) === 1) ? {}: {'margin-left':'10%'}">
      <div class="content is-relative p-6 m-3-mobile has-background-kanji has-background-kanji__mobile" :style="`background-image:url(${item.kanji})`">
        <p class="title is-3 has-text-theme-red">{{item.title}}</p>
        <p class="p-3 is-relative z-2" v-html="item.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import map from 'lodash/map';
//import config from './../config.json';
const CONTENTS = [
  {
    src: './static_img/img_02-01.png',
    alt: '払う',
    title: '払う',
    kanji: './static_img/img_kanji-01.png',
    text: '木部の汚れた表面を優しく払います。チップを吹き付ける圧力を調整することが可能で彫刻や重要文化財など、繊細な作業を必要とする対象物にも採用されています。'
  },
  {
    src: './static_img/img_02-02.png',
    alt: '削る',
    title: '削る',
    kanji: './static_img/img_kanji-02.png',
    text: '対象の木材の表面を数ミクロンから数センチ削る事が可能です。<br>劣化した壁やカビ、木材腐朽菌を除去したり、浮造り加工などの意匠性にも対応できます。'
  },
  {
    src: './static_img/img_02-03.png',
    alt: '剥ぐ',
    title: '剥ぐ',
    kanji: './static_img/img_kanji-03.png',
    text: '木材の表面にある、既存の劣化塗膜を剥離することが可能です。<br>基材を傷めることはありません。細かい粒子状の木質チップを吹き付けるので複雑な形状でも早く・綺麗に施工が可能です。'
  },
  {
    src: './static_img/img_02-04.png',
    alt: '粗す',
    title: '粗す',
    kanji: './static_img/img_kanji-04.png',
    text: '木材の表面に無数の小さな孔をつくるため、塗料の吸い込みが通常の２〜３倍になります。'
  },
];

export default {
  name: 'parts-interview',
  components: {
  },
  data() {
    return {
      debug: false,
    }
  },
  computed: {
    CONTENTS() {
      return map(CONTENTS, (content) => {
        if (this.debug) {
          content.src = "https://bulma.io/images/placeholders/128x128.png";
        }
        return content;
      });
    }
  }
}
</script>

<style scoped>
.kanji {
  font-size: 7rem;
  top: 80%;
  left: 80%;
}
.mb-100p {
  margin-bottom: 100% !important;
}
.has-background-kanji {
  background-repeat: no-repeat;
  background-position: right 10% bottom 10%;
  background-size: 35%;
  height: 450px;
}
.has-background-kanji__mobile {
  height: 300px;
}
</style>
