<template>
  <div class="" v-for="(item, index) in CONTENTS" :key="index">
    <div class="w-100">
      <div class="content">
        <p class="title is-3 mt-3 has-text-centered mincho" v-html="item.title"></p>
        <p class="is-size-4">{{item.text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
//import config from './../config.json';

const CONTENTS = [
  {
    "title": '<span class="has-text-theme-red">寺・神社</span>の住職様へ',
    "text": "木曾路はすべて山の中である。あるところは岨づたいに行く崖の道であり、あるところは数十間の深さに臨む木曾川の岸であり、あるところは山の尾をめぐる谷の入り口である。一筋の街道はこの深い森林地帯を貫いていた",
  }
];

export default {
  name: 'parts-method-targets',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
</style>
