<template>
  <div class="columns is-relative">
    <div class="divider centering w-80 has-background-theme-yellow is-hidden-mobile" style="top:55%;"></div>

    <template v-for="(item, index) in CONTENTS" :key="index">
      <div class="column is-3 mobile-w80 m-auto" style="max-width:320px;">
        <div class="has-background-theme-grey is-relative flow-h">
          <p class="has-text-white centering z-2 step" style="top:90%;left:75%;">{{item.step}}</p>
          <figure class="image is-128x128 m-auto p-4">
            <img class="object-fit-cover" :src="item.src" :alt="item.alt">
          </figure>
          <p class="has-text-centered title is-4 py-3" v-html="item.title"></p>
          <p class="p-4 z-3 is-relative z-3 text-h" v-html="item.text"></p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import config from './../config.json';

export default {
  name: 'parts-flow',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return config.FLOW_CONTENTS;
    }
  }
}
</script>

<style scoped>
.divider {
  height: 2px;
}
.step {
  font-size: 80px;
  font-weight: bold;
}
.flow-h {
  height: 375px;
}
.text-h {
  height: 128px;
}
</style>
